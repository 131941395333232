/* eslint-disable @typescript-eslint/naming-convention */
import { DatePipe } from '@angular/common';
import { InspectionWithResultsDto } from '@core/api';
import { Constants } from '@core/constants';
import { TranslateService } from '@ngx-translate/core';
import { AcroFormCheckBox, AcroFormTextField, jsPDF } from 'jspdf';
import 'jspdf-autotable';
import { ImagesDto } from 'src/app/dtos/imagesDto';
import { ProtocolInspectionService } from './protocol-inspection-service';
import { PdfField } from './pdf-field';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export abstract class ProtocolPdfFrMilkProducersService {
  protected document: jsPDF;
  protected isCurrentLanguageFr: boolean;
  protected readonly fontSize = 7.7;
  protected readonly requirementFontSize = 8.5;
  protected readonly tableSeparationSpace = 1.7;
  protected readonly cellPaddingTop = 1;
  protected readonly lineHeight = 1.3;
  protected readonly separationSpace = 3;
  protected readonly minPositionX = 15;
  protected readonly requirementColumnCheckboxWidth = 6.85;
  protected readonly requirementColumnCheckboxNotWidth = 7.95;
  protected readonly requirementColumnArticleNumberWidth = 6;
  protected readonly requirementColumnArticleTextWidth = 18;
  protected protocolInspectionService: ProtocolInspectionService;
  protected pageHeaderHeight = 19;
  private readonly checkboxSize = 3;
  protected abstract prefixFileName: string;
  protected abstract translationKeyPageHeaderTitle: string;
  protected abstract pageFooterDateAsText: string;

  public constructor(
    protected translateService: TranslateService,
    private inspectionWithResult: InspectionWithResultsDto,
    private pdfImages: ImagesDto,
    private datePipe: DatePipe
  ) {}

  public generateProtocolPdf(): void {
    this.document = new jsPDF();
    this.protocolInspectionService = new ProtocolInspectionService(
      this.translateService,
      this.inspectionWithResult,
      this.datePipe
    );
    this.setIsCurrentLanguageFr();
    this.setAutoTableDefaultStyles();
    this.addTablesToPages();
    this.addPageHeaderAndFooter();
    this.saveFile();
  }

  protected setAutoTableDefaultStyles(): void {
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica');
    //@ts-ignore
    this.document.autoTableSetDefaults({
      theme: 'grid',
      styles: { fontSize: this.fontSize },
      headStyles: {
        fillColor: ProtocolPdfConstants.grayColor,
        textColor: 20,
        fontStyle: 'normal',
        lineWidth: 0.2,
        cellPadding: 1.2,
      },
      bodyStyles: {
        textColor: 20,
        minCellHeight: 1,
        cellPadding: 1.2,
      },
    });
  }

  protected addUnitTable(): void {
    //@ts-ignore
    this.document.autoTable({
      margin: { top: this.pageHeaderHeight },
      columnStyles: {
        0: { cellWidth: 20 },
        2: { cellWidth: 40 },
        3: { cellWidth: 65 },
      },
      body: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow1Column1'),
          '',
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow1Column2'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow1Column3'),
        ],
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow2Column1'),
          '',
          '',
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow2Column3'),
        ],
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow3Column1'),
          '',
          '',
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow3Column3'),
        ],
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow4Column1'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow4Column2'),
          this.protocolInspectionService.getCanton(),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow4Column4'),
        ],
      ],

      didParseCell: (data: any) => {
        this.applySpansToUnitTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToUnitTable(data);
      },
    });
  }

  protected addAuditorTable(): void {
    //@ts-ignore
    this.document.autoTable({
      columnStyles: {
        1: { cellWidth: 59 },
        2: { cellWidth: 65 },
      },
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      body: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereAuditorRow1Column1'),
          this.protocolInspectionService.getControlCenter(),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereAuditorRow1Column3'),
        ],
      ],
      didDrawCell: (data: any) => {
        this.addAcroFieldsToAuditorTable(data);
      },
    });
  }

  protected addMilkProducersTable(headTitle: string, numberOfRows: number): void {
    const body = this.createRows(numberOfRows, [
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereNameCheeseFactory'),
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereUnitRow1Column3'),
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereProducerNumber'),
    ]);
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      columnStyles: {
        1: { cellWidth: 45 },
        2: { cellWidth: 45 },
      },
      head: [[headTitle, '', '']],
      body,
      didParseCell: (data: any) => {
        this.applySpansToMilkProducersTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToMilkProducersTable(data);
      },
    });
  }

  protected addRequirementsCheckboxes(
    data: any,
    startColumnIndex: number,
    skipSpanRows?: number[]
  ): void {
    const skipRow =
      skipSpanRows != null ? skipSpanRows.find((rowIndex) => rowIndex === data.row.index) : false;
    if (!skipRow && data.section === 'body' && data.column.index > startColumnIndex) {
      this.addCheckboxAlignCenter(data);
    }
  }

  protected applyFontsizeToColumnRequirement(data: any) {
    if (data.section === 'body' && (data.column.index === 2 || data.column.index === 3)) {
      data.cell.styles.fontSize = this.requirementFontSize;
    }
  }

  protected addConfirmationTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY(),
      columnStyles: {
        0: { cellWidth: 59 },
        2: { cellWidth: 59 },
      },
      body: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereConfirmationRow1Col1'),
          '',
          '',
        ],
        [
          ' ',
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereConfirmationRow2Col2'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereConfirmationRow2Col3'),
        ],
      ],
      didParseCell: (data: any) => {
        this.applySpansToConfirmationTable(data);
        this.applyAlignmentsToConfirmationTable(data);
        this.applyStylesToConfirmationTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToConfirmationTable(data);
      },
    });
  }

  protected addRemarksLegend(): number {
    this.setPagePositionY(0.7);
    const firstLineSpace = 3;
    const resultLegendSpaceY = firstLineSpace;
    const positionY = this.getPagePositionY() + firstLineSpace;
    let positionX = 32;
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'italic');
    const textPart1 = this.translateService.instant(
      'ProtocolPdf.VacherinAndGruyereRemarksLegendRow1Part1'
    );
    this.document.text(textPart1, positionX, positionY);
    positionX += this.document.getTextWidth(textPart1) + this.cellPaddingTop;
    this.document.setFont('helvetica', 'bolditalic');
    const textPart2 = this.translateService.instant(
      'ProtocolPdf.VacherinAndGruyereRemarksLegendRow1Part2'
    );
    this.document.text(textPart2, positionX, positionY);
    positionX += this.document.getTextWidth(textPart2) + this.cellPaddingTop;
    this.document.setFont('helvetica', 'italic');
    this.document.text(
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereRemarksLegendRow1Part3'),
      positionX,
      positionY
    );
    return resultLegendSpaceY + this.tableSeparationSpace;
  }

  protected addRemarksTable(legendSpaceY: number, numberOfRows: number): void {
    const body = this.createRows(numberOfRows, ['', '']);
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + legendSpaceY,
      headStyles: { halign: 'center' },
      columnStyles: {
        0: { cellWidth: 25 },
      },
      head: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereRemarksHeaderCol1'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereRemarksHeaderCol2'),
        ],
      ],
      body,
      didParseCell: (data: any) => {
        this.applyStylesToRemarksTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToRemarksTable(data);
      },
    });
  }

  protected addAcroCheckbox(data: any, label?: string, field?: PdfField): number {
    const checkbox = new AcroFormCheckBox();
    checkbox.appearanceState = 'Off';
    checkbox.height = this.checkboxSize;
    checkbox.width = this.checkboxSize;
    checkbox.fontSize = ProtocolPdfConstants.smallFontSize;
    this.calculateFieldPosition(data, checkbox, field);
    this.document.addField(checkbox);
    let fieldPositionX = checkbox.x + checkbox.width;
    if (label == null) {
      return fieldPositionX;
    }
    fieldPositionX += checkbox.width / 2;
    const fieldPositionY = checkbox.y + checkbox.height / 2 + this.cellPaddingTop;
    this.document.text(label, fieldPositionX, fieldPositionY);
    this.drawBorder(checkbox);
    return fieldPositionX + this.document.getTextWidth(label) + this.separationSpace;
  }

  protected addAcroTextbox(data: any, field: PdfField, value?: string): PdfField {
    const textbox = new AcroFormTextField();
    this.calculateFieldPosition(data, textbox, field);
    textbox.width = field.width;
    textbox.height = 4.1;
    if (value != null) {
      textbox.value = value;
      textbox.fontSize = ProtocolPdfConstants.smallFontSize;
    } else {
      textbox.fontSize = ProtocolPdfConstants.bigFontSize;
    }
    this.document.addField(textbox);
    return textbox;
  }

  protected setIsCurrentLanguageFr(): void {
    const language = this.translateService.currentLang;
    this.isCurrentLanguageFr = language != null && language === 'fr';
  }

  protected createRows(numberOfRows: number, cells: any): any[] {
    const rows = [];
    for (let i = 0; i < numberOfRows; i++) {
      rows.push(cells);
    }
    return rows;
  }

  protected getSpanRowsHeight(data: any, start: number, end: number): number {
    let result = 0;
    for (start; start <= end; start++) {
      result += data.row.height;
    }
    return result;
  }

  protected saveFile(): void {
    this.document.save(this.protocolInspectionService.getFileName(this.prefixFileName));
  }

  protected getPagePositionY(): number {
    //@ts-ignore
    return this.document.lastAutoTable.finalY;
  }

  protected setPagePositionY(value: number): void {
    //@ts-ignore
    this.document.lastAutoTable.finalY += value;
  }

  protected resetToDefaultFontSettings(): void {
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'normal');
  }

  protected drawCellTopBorder(data: any): void {
    this.document.setLineWidth(0.1);
    const startPositionX = data.cell.x;
    const endPositionX = data.cell.x + data.cell.width;
    const positionYTop = data.cell.y;
    this.document.line(startPositionX, positionYTop, endPositionX, positionYTop);
  }

  protected drawCellBottomBorder(data: any): void {
    this.document.setLineWidth(0.1);
    const startPositionX = data.cell.x;
    const endPositionX = data.cell.x + data.cell.width;
    const positionYBottom = data.cell.y + data.cell.height;
    this.document.line(startPositionX, positionYBottom, endPositionX, positionYBottom);
  }

  protected drawCellLeftBorder(data: any): void {
    this.document.setLineWidth(0.2);
    const positionX = data.cell.x;
    const startPositionY = data.cell.y;
    const endPositionY = data.cell.y + data.cell.height;
    this.document.line(positionX, startPositionY, positionX, endPositionY);
  }

  protected drawCellRightBorder(data: any): void {
    this.document.setLineWidth(0.2);
    const positionX = data.cell.x + data.cell.width;
    const startPositionY = data.cell.y;
    const endPositionY = data.cell.y + data.cell.height;
    this.document.line(positionX, startPositionY, positionX, endPositionY);
  }

  protected removeCellBorder(data: any): void {
    data.cell.styles.lineWidth = 0;
  }

  protected addCheckboxAlignCenter(data: any, rowHeight?: number) {
    const resultRowHeight = rowHeight ? rowHeight : data.row.height;
    const alignCenterPositionX = data.cell.x + data.cell.width / 2 - this.checkboxSize / 2;
    const alignCenterPositionY = data.cell.y + resultRowHeight / 2 - this.checkboxSize / 2;
    this.addAcroCheckbox(data, null, { x: alignCenterPositionX, y: alignCenterPositionY });
  }

  protected applyAlignmentInCenterToHeaderRequirement(data: any): void {
    const columnIndexStartRequirement = 2;
    if (
      data.section === 'head' &&
      (data.column.index === 0 || data.column.index === columnIndexStartRequirement)
    ) {
      data.cell.styles.valign = 'middle';
    }
  }

  protected applySpansToHeaderRequirement(
    data: any,
    columnIndexStartCheckboxes: number,
    numberOfColumnsToSpanRequirement: number
  ): void {
    const columnIndexStartRequirement = 2;
    if (data.section === 'head') {
      if (data.row.index === 0 && data.column.index === columnIndexStartCheckboxes) {
        data.cell.colSpan = 3;
      }
      if (
        data.row.index === 0 &&
        (data.column.index === 0 || data.column.index === columnIndexStartRequirement)
      ) {
        data.cell.rowSpan = 2;
      }
      if (data.column.index === 0) {
        data.cell.colSpan = 2;
      }
      if (
        data.column.index === columnIndexStartRequirement &&
        numberOfColumnsToSpanRequirement > 1
      ) {
        data.cell.colSpan = numberOfColumnsToSpanRequirement;
      }
    }
  }

  protected calculateFieldPositionX(data: any): number {
    const cellTextWidth = this.getCellTextWidth(data);
    return data.cell.x + cellTextWidth + this.separationSpace;
  }

  protected calculateFieldPositionY(data: any): number {
    let positionY = data.cell.y;
    const multilineText = this.document.splitTextToSize(data.cell.raw, data.cell.contentWidth);
    if (multilineText.length > 0) {
      const separationBetweenlines = 0.1 * multilineText.length;
      positionY +=
        multilineText.length === 1
          ? this.cellPaddingTop
          : multilineText.length * (this.cellPaddingTop + this.lineHeight + separationBetweenlines);
    }
    return positionY;
  }

  private drawBorder(field: PdfField): void {
    this.document.rect(field.x, field.y, field.width, field.height);
  }

  private calculateFieldPosition(data: any, acroFormField: PdfField, field?: PdfField): void {
    if (field != null && field.y != null) {
      acroFormField.y = field.y;
    } else {
      acroFormField.y = this.calculateFieldPositionY(data);
    }
    if (field != null && field.broaderMarginLeft != null) {
      acroFormField.y += field.broaderMarginLeft;
    }

    if (field != null && field.x != null) {
      acroFormField.x = field.x;
    } else {
      acroFormField.x = this.calculateFieldPositionX(data);
    }
    if (field != null && field.broaderMarginTop != null) {
      acroFormField.x += field.broaderMarginTop;
    }
  }

  private addAcroFieldsToAuditorTable(data: any): void {
    if (data.section === 'body') {
      if (data.row.index === 0 && data.column.index === 0) {
        this.addAcroTextbox(
          data,
          { width: this.isCurrentLanguageFr ? 41 : 42.5 },
          this.protocolInspectionService.getInspectorName()
        );
      }
      if (data.row.index === 0 && data.column.index === 2) {
        this.addAcroTextbox(
          data,
          { width: this.isCurrentLanguageFr ? 38.5 : 35 },
          this.protocolInspectionService.getCurrentDate()
        );
      }
    }
  }

  private getCellTextWidth(data: any): number {
    let textWidth = 0;
    const multilineText = this.document.splitTextToSize(data.cell.raw, data.cell.contentWidth);
    if (multilineText.length > 0) {
      const lastText = multilineText[multilineText.length - 1];
      textWidth = this.document.getTextWidth(lastText);
    }
    return textWidth;
  }

  private addAcroFieldsToMilkProducersTable(data: any): void {
    let textboxWidthCol1 = 62.5;
    const textboxWidthCol2 = 18;
    let textboxWidthCol3 = 18;
    if (this.isCurrentLanguageFr) {
      textboxWidthCol1 = 58.5;
      textboxWidthCol3 = 20.5;
    }
    if (data.section === 'body') {
      if (data.column.index === 0) {
        this.addAcroTextbox(data, { width: textboxWidthCol1 });
      }
      if (data.column.index === 1) {
        this.addAcroTextbox(data, { width: textboxWidthCol2 });
      }
      if (data.column.index === 2) {
        this.addAcroTextbox(data, { width: textboxWidthCol3 });
      }
    }
  }

  private applySpansToUnitTable(data: any): void {
    if (data.section === 'body') {
      if (data.row.index === 0 && data.column.index === 0) {
        data.cell.colSpan = 2;
      }
      if (data.row.index === 1 && data.column.index === 0) {
        data.cell.colSpan = 3;
      }
      if (data.row.index === 2 && data.column.index === 0) {
        data.cell.colSpan = 3;
      }
    }
  }

  private addAcroFieldsToUnitTable(data: any): void {
    if (data.section === 'body') {
      this.addAcroFieldsToUnitRow1(data);
      this.addAcroFieldsToUnitRow2(data);
      this.addAcroFieldsToUnitRow3(data);
      this.addAcroFieldsToUnitRow4(data);
    }
  }

  private addAcroFieldsToUnitRow1(data: any) {
    if (data.row.index === 0 && data.column.index === 0) {
      this.addAcroTextbox(data, { width: 62.5 }, this.protocolInspectionService.getName());
    }
    if (data.row.index === 0 && data.column.index === 2) {
      this.addAcroTextbox(data, { width: 25 });
    }
    if (data.row.index === 0 && data.column.index === 3) {
      this.addAcroTextbox(data, { width: this.isCurrentLanguageFr ? 39.5 : 39 });
    }
  }

  private addAcroFieldsToUnitRow2(data: any) {
    if (data.row.index === 1 && data.column.index === 0) {
      this.addAcroTextbox(
        data,
        { width: this.isCurrentLanguageFr ? 83.5 : 82.5 },
        this.protocolInspectionService.getResponsiblePersonName()
      );
    }
    if (data.row.index === 1 && data.column.index === 3) {
      this.addAcroTextbox(
        data,
        { width: 52 },
        this.protocolInspectionService.getResponsiblePersonPhones()
      );
    }
  }

  private addAcroFieldsToUnitRow3(data: any) {
    if (data.row.index === 2 && data.column.index === 0) {
      this.addAcroTextbox(
        data,
        { width: 100.5 },
        this.protocolInspectionService.getResponsiblePersonAddress()
      );
    }
    if (data.row.index === 2 && data.column.index === 3) {
      this.addAcroTextbox(
        data,
        { width: this.isCurrentLanguageFr ? 47 : 48.5 },
        this.protocolInspectionService.getResponsiblePersonMobilePhone()
      );
    }
  }

  private addAcroFieldsToUnitRow4(data: any) {
    if (data.row.index === 3 && data.column.index === 0) {
      this.addAcroTextbox(
        data,
        { width: 10 },
        this.protocolInspectionService.getUnitResponsiblePostalZone()
      );
    }
    if (data.row.index === 3 && data.column.index === 1) {
      this.addAcroTextbox(
        data,
        { width: 45 },
        this.protocolInspectionService.getUnitResponsibleTown()
      );
    }
    if (data.row.index === 3 && data.column.index === 3) {
      this.addAcroTextbox(
        data,
        { width: this.isCurrentLanguageFr ? 54 : 53.5 },
        this.protocolInspectionService.getResponsiblePersonEmail()
      );
    }
  }

  private applySpansToMilkProducersTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
      data.cell.colSpan = 3;
    }
  }

  private applyStylesToRemarksTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 1) {
      data.cell.styles.halign = 'left';
      data.cell.styles.fontStyle = 'bold';
    }
  }

  private addAcroFieldsToRemarksTable(data: any): void {
    if (data.section === 'body') {
      if (data.column.index === 0) {
        const textboxCol1Width = 17.5;
        this.addAcroTextbox(data, { width: textboxCol1Width });
      }
      if (data.column.index === 1) {
        const textboxCol2Width = 150;
        this.addAcroTextbox(data, { width: textboxCol2Width });
      }
    }
  }

  private applySpansToConfirmationTable(data: any): void {
    if (data.section === 'body' && data.row.index === 0 && data.column.index === 0) {
      data.cell.colSpan = 3;
    }
  }

  private applyAlignmentsToConfirmationTable(data: any): void {
    if (data.section === 'body' && data.row.index === 1 && data.column.index === 0) {
      data.cell.styles.valign = 'bottom';
      data.cell.styles.halign = 'left';
    }
  }

  private applyStylesToConfirmationTable(data: any): void {
    if (data.section === 'body' && data.row.index === 0 && data.column.index === 0) {
      data.cell.styles.fillColor = ProtocolPdfConstants.grayColor;
      data.cell.styles.fontStyle = 'italic';
    }
  }

  private addAcroFieldsToConfirmationTable(data: any): void {
    if (data.section === 'body' && data.row.index === 1 && data.column.index === 0) {
      this.addAcroTextbox(
        data,
        { width: 55, broaderMarginLeft: 6, broaderMarginTop: -2 },
        this.protocolInspectionService.getLocationAndCurrentDate()
      );
    }
  }

  private addPageHeaderAndFooter(): void {
    const pageCount = this.document.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      this.addPageHeader();
      this.addPageFooter(i, pageCount);
    }
  }

  private addPageHeader(): void {
    this.document.setFontSize(10);
    this.document.setTextColor(40);
    const headerPaddingTop = 3;
    let positionY = 5 + headerPaddingTop;
    this.document.text(
      this.translateService.instant(this.translationKeyPageHeaderTitle),
      this.minPositionX,
      positionY
    );
    const imageData1 = this.getImageData(Constants.imageVfrCl);
    const imageData2 = this.getImageData(Constants.imageVfrCl2);
    let positionX = this.document.internal.pageSize.width / 2;
    positionY = 2.5 + headerPaddingTop;
    this.document.addImage(imageData1, 'jpg', positionX, positionY, 8.5, 8.69);
    positionX += 25;
    positionY = 1.25 + headerPaddingTop;
    const imageHeight = 12;
    this.document.addImage(imageData2, 'jpg', positionX, positionY, 62, 12);
    this.pageHeaderHeight = positionY + imageHeight;
  }

  private addPageFooter(pageNumber: number, pageCount: number): void {
    this.document.setFontSize(7.7);
    const lineHeight = 4;
    const pageHeight =
      this.document.internal.pageSize.height || this.document.internal.pageSize.getHeight();
    let positionX = this.minPositionX;
    let positionY = pageHeight - lineHeight * 2;
    this.document.text(
      this.translateService.instant('ProtocolPdf.VacherinAndGruyerePageFooterRow1'),
      positionX,
      positionY
    );
    positionY = pageHeight - lineHeight;
    this.document.text(
      this.translateService.instant(this.getTranslationKeyPageFooterRow2Part1()),
      positionX,
      positionY
    );
    this.document.setPage(pageNumber);
    const pageText = `${this.translateService.instant(
      'ProtocolPdf.VacherinAndGruyerePageFooterRow2Part2'
    )} ${pageNumber} / ${pageCount}`;
    positionX = this.document.internal.pageSize.width / 2;
    this.document.text(pageText, positionX, positionY, { align: 'center' });
    positionX += this.document.internal.pageSize.width / 2.5;
    this.document.text(this.pageFooterDateAsText, positionX, positionY, { align: 'center' });
  }

  private getImageData(imageName: string): string {
    return this.pdfImages.images.find((image) => image.name === imageName).imageData;
  }

  protected abstract addTablesToPages(): void;
  protected abstract getTranslationKeyPageFooterRow2Part1(): string;
}
