import { jsPDF } from 'jspdf';
import { InspectionWithResultsDto } from '../api';
import { ProtocolPdfConstants } from './protocol-pdf.constants';
import { ProtocolPdfStandardService } from './protocol-pdf-standard.service';
import { DefectTableParams } from './protocol-defect-table-params';
import { InspectionResultAccessor } from './inspection-result-accessor';
import { ProtocolPdfDto } from 'src/app/dtos/protocolPdfDto';
import { Constants } from '@core/constants';

export class ProtocolPdfZhService extends ProtocolPdfStandardService {
  public generateProtocolPdf(protocolPdfDto: ProtocolPdfDto) {
    const originalLanguage = this.translateService.currentLang;
    this.translateService.use(protocolPdfDto.language);
    this.pdfDocument = new jsPDF();
    let baseY = this.addPageHeader() + this.getNewLine(2, 2);
    baseY = this.addInspectionTitle(baseY, 'ProtocolPdf.HeaderVetReport2');
    baseY = this.addInspectionPersonInformation(protocolPdfDto.inspection, baseY);
    baseY = this.addInspectionHeader(protocolPdfDto.inspection, protocolPdfDto.protocol, baseY);
    const inspectionResults = this.inspectionResultsService.getInspectionResultsByAccessor({
      ...protocolPdfDto,
      inspectionResultAccessor: InspectionResultAccessor.defectOrFieldValue,
    });

    baseY = this.addInspectionResultsWithOnTop(protocolPdfDto, baseY);
    baseY += this.getNewLine(1, 3);

    baseY = this.addInspectionResultsWithoutDefect(protocolPdfDto, baseY);
    baseY = this.addInspectionResults(protocolPdfDto, inspectionResults, baseY);
    baseY = this.addSpaceBetweenResultsAndCheckboxes(inspectionResults.size, baseY);
    baseY = this.addDocumentationCheckboxes(baseY);
    baseY = this.addCommentOfDepartment(protocolPdfDto, baseY);
    baseY = this.addStatement(protocolPdfDto.protocol, baseY);
    baseY = this.addFurtherAction(protocolPdfDto.protocol, baseY);
    baseY = this.addFees(protocolPdfDto.protocol.fees, baseY);
    baseY = this.addSignatureBlock(protocolPdfDto.inspection, baseY) + this.getNewLine(2);
    baseY = this.addFurtherProcedureDetails(baseY);
    baseY = this.addFurtherProcedureDetailsAbbreviations(baseY);
    if (protocolPdfDto.protocol.furtherAction.feedback) {
      this.addInspectionResultsCorrections(
        protocolPdfDto.inspection,
        protocolPdfDto.protocol,
        inspectionResults
      );
    }
    this.addFooter(protocolPdfDto.inspection);
    this.pdfDocument.save(this.getProtocolFileName(protocolPdfDto));
    this.translateService.use(originalLanguage);
  }

  protected addSpaceBetweenResultsAndCheckboxes(size: number, baseY: number): number {
    baseY += size ? 10 : ProtocolPdfConstants.lineHeight;
    if (size === 0) {
      baseY += this.getNewLine(1);
    }
    return baseY;
  }

  protected getProtocolFileName(protocolPdfDto: ProtocolPdfDto): string {
    return `kontrolle-${protocolPdfDto.inspection.inspectionId}.pdf`;
  }

  protected addPageHeader(): number {
    const marginHeaderColumn = 145;
    const lineHeight = 4;
    this.pdfDocument.setFont('helvetica');
    let imageData = this.getImageData(Constants.imageZh1);
    this.addImage(
      imageData,
      'jpg',
      ProtocolPdfConstants.marginX - 15,
      ProtocolPdfConstants.marginYFirstPage - 3,
      12,
      16
    );
    this.pdfDocument.setFontSize(9);
    this.setFontType(ProtocolPdfConstants.boldFontType);
    this.pdfDocument.text(
      'Veterinäramt',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage
    );
    this.setFontType(ProtocolPdfConstants.defaultFontType);
    this.pdfDocument.text(
      '8090 Zürich',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 1 * lineHeight
    );
    this.pdfDocument.text(
      'Telefon 043 259 41 41, Fax 043 259 41 40',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 2 * lineHeight
    );
    this.pdfDocument.text(
      'kanzlei@veta.zh.ch, zh.ch/veta',
      ProtocolPdfConstants.marginX,
      ProtocolPdfConstants.marginYFirstPage + 3 * lineHeight
    );
    imageData = this.getImageData(Constants.imageZh2);
    this.addImage(
      imageData,
      'jpg',
      marginHeaderColumn - 10,
      ProtocolPdfConstants.marginYFirstPage,
      8,
      8
    );
    this.pdfDocument.text(
      'Kanton Zürich',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 3
    );
    this.pdfDocument.text(
      'Gesundheitsdirektion',
      marginHeaderColumn,
      ProtocolPdfConstants.marginYFirstPage + 3 + lineHeight
    );
    return 24;
  }

  protected addSignatureBlock(inspection: InspectionWithResultsDto, baseY: number): number {
    baseY = this.addSignatureDate(baseY, inspection);
    baseY = this.addSignaturePersons(baseY);
    baseY = this.addSignatureAdditionalInformationCheckboxes(baseY);
    return baseY;
  }

  protected getInspectionPersonTranslationKey(): string {
    return this.translateService.instant('ProtocolPdf.LabelSignatureInspectionPerson');
  }

  protected getInspectionResponsiblePersonTranslationKey(): string {
    return this.translateService.instant('ProtocolPdf.LabelSignatureResponsiblePerson');
  }

  protected addLabelSignatureInspectionPerson(baseY: number): number {
    return this.writeText(
      this.getInspectionPersonTranslationKey(),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel +
        ProtocolPdfConstants.signatureBlockWidthDottedLine,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
  }

  protected addLabelSignatureResponsiblePerson(baseY: number): number {
    const margin2ndColumn = ProtocolPdfConstants.marginX + 80;
    baseY = this.writeText(
      this.getInspectionResponsiblePersonTranslationKey(),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - 1,
      margin2ndColumn,
      baseY,
      1
    );
    return baseY;
  }

  protected getTextOptionFurtherProcedureDetailsTranslationKey(detailNumber: number): string {
    return this.translateService.instant(
      `ProtocolPdf.TextOptionFurtherProcedureDetailsZh${detailNumber}`
    );
  }

  protected getTextHeaderFurtherProcedureDetails(): string {
    return this.translateService.instant('ProtocolPdf.TextHeaderFurtherProcedureDetailsZh');
  }

  protected getTextOptionFurtherProcedureDetails1(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZh1`);
  }

  protected getTextOptionFurtherProcedureDetails2(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZh2`);
  }

  protected getTextOptionFurtherProcedureDetails3(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZh3`);
  }

  protected getTextOptionFurtherProcedureDetails4(): string {
    return this.translateService.instant(`ProtocolPdf.TextOptionFurtherProcedureDetailsZh4`);
  }

  protected getDefectTableParams(): DefectTableParams {
    const defectTableParams = new DefectTableParams();
    defectTableParams.deadlineText = 'ProtocolPdf.TextConfirmationDeadlineZh';
    defectTableParams.defectCorrectionTableHeaderCorrected =
      'ProtocolPdf.DefectCorrectionTableHeaderDeadlineUntil';
    defectTableParams.labelRemarks = ['ProtocolPdf.LabelDefectCorrectionRemarks'];
    defectTableParams.addCheckboxPhotos = true;
    defectTableParams.ktIdOnHeader = true;
    return defectTableParams;
  }

  protected addSignatureDate(baseY: number, inspection: InspectionWithResultsDto) {
    const signatureBlockHeight = 9 * ProtocolPdfConstants.lineHeight;
    if (baseY + signatureBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelDate'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    const dateText = this.datePipe.transform(
      inspection.inspectionDate,
      ProtocolPdfConstants.dateFormat
    );
    this.writeText(
      dateText,
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.signatureBlockWidthLabel - 1,
      ProtocolPdfConstants.marginX + 17,
      baseY,
      1
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addSignaturePersons(baseY: number): number {
    this.addLabelSignatureInspectionPerson(baseY);
    baseY = this.addLabelSignatureResponsiblePerson(baseY);
    baseY += 5 * ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addSignatureAdditionalInformationCheckboxes(baseY: number) {
    const marginColumn = ProtocolPdfConstants.marginX + 80;
    const marginFirstText = 4;
    const marginSecondCheckbox = 49;
    const marginSecondText = 53;
    this.addAcroCheckBox(3, marginColumn, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitAbsent'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      marginSecondCheckbox - marginFirstText - 1,
      marginColumn + marginFirstText,
      baseY
    );
    this.addAcroCheckBox(3, marginColumn + marginSecondCheckbox, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitAbsentLetter'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - marginSecondText - 1,
      marginColumn + marginSecondText,
      baseY
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.addAcroCheckBox(3, marginColumn + marginSecondCheckbox, baseY);
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelUnitAbsentDeposit'),
      ProtocolPdfConstants.smallFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - marginSecondText - 1,
      marginColumn + marginSecondText,
      baseY
    );
    baseY += 2 * ProtocolPdfConstants.lineHeight + 10;
    return baseY;
  }

  protected addFurtherProcedureDetailsAbbreviations(baseY: number) {
    const abbreviationBlockHeight = 3 * ProtocolPdfConstants.lineHeight;
    if (baseY + abbreviationBlockHeight > ProtocolPdfConstants.maxYValue) {
      baseY = this.newPage();
    }
    const abbreviationWidth = 15;
    this.writeText(
      this.translateService.instant('ProtocolPdf.LabelAbbreviations'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.boldFontType,
      ProtocolPdfConstants.maxLineWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextAbbreviationAln'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      abbreviationWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextAln'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - abbreviationWidth,
      ProtocolPdfConstants.marginX + abbreviationWidth,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextAbbreviationVeta'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      abbreviationWidth,
      ProtocolPdfConstants.marginX,
      baseY,
      1
    );
    this.writeText(
      this.translateService.instant('ProtocolPdf.TextVeta'),
      ProtocolPdfConstants.defaultFontSize,
      ProtocolPdfConstants.defaultFontType,
      ProtocolPdfConstants.maxXValue - abbreviationWidth,
      ProtocolPdfConstants.marginX + abbreviationWidth,
      baseY,
      1
    );
    baseY += ProtocolPdfConstants.lineHeight;
    return baseY;
  }

  protected addInspectionResultsWithOnTop(protocolPdfDto: ProtocolPdfDto, baseY: number): number {
    return baseY;
  }
}
