import { ProtocolPdfFrBaseService } from './protocol-pdf-fr-base.service';

export class ProtocolPdfFrViehhandelService extends ProtocolPdfFrBaseService {
  protected addAccredationLogo() {
    //skip the accredation logo
  }

  protected getHeaderAddressTo(): string {
    return 'ProtocolPdf.HeaderFr3';
  }

  protected getFurtherProcedureDetailsLegalRemedies(): string {
    return 'ProtocolPdf.TextOptionFurtherProcedureDetailsFr4';
  }

  protected getTextOrderLSVW(): string {
    return 'ProtocolPdf.TextOrderLSVW2';
  }
}
