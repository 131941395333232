/* eslint-disable @typescript-eslint/naming-convention */
import { ProtocolPdfFrMilkProducersService } from './protocol-pdf-fr-milk-producers-service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class ProtocolPdfFrGruyereService extends ProtocolPdfFrMilkProducersService {
  protected pageFooterDateAsText = '01-06-21';
  protected translationKeyPageHeaderTitle = 'ProtocolPdf.GruyerePageHeader';
  protected prefixFileName = 'GRU';

  protected addTablesToPages(): void {
    this.addTablesToPage1();
    this.addTablesToPage2();
    this.addTablesToPage3();
  }

  protected getTranslationKeyPageFooterRow2Part1(): string {
    return 'ProtocolPdf.GruyerePageFooterRow2Part1';
  }

  private addTablesToPage1(): void {
    this.addUnitTable();
    this.addAuditorTable();
    this.addMilkProductionUnitTable();
    this.addMilkProducersTable(
      this.translateService.instant('ProtocolPdf.GruyereMilkProducersHeader'),
      3
    );
    const separationSpaceLegend = this.addRequirementsLegend();
    this.addRequirementsTableToPage1(separationSpaceLegend);
  }

  private addTablesToPage2(): void {
    this.addRequirementsTableToPage2();
    const legendSpaceY = this.addRemarksLegend();
    let numberOfRows = 6;
    if (this.isCurrentLanguageFr) {
      numberOfRows = 4;
    }
    this.addRemarksTable(legendSpaceY, numberOfRows);
    this.addConfirmationTable();
  }

  private addTablesToPage3(): void {
    this.addAnnexTable();
    this.addPerYearTable();
    this.addTotalConsumptionTable();
    this.addPurchasesTable();
    this.addProportionTable();
  }

  private addMilkProductionUnitTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      columnStyles: {
        0: { cellWidth: 59 },
        1: { cellWidth: 59 },
        2: { cellWidth: 63.5 },
      },
      head: [
        [
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart1'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart2'),
          '',
        ],
      ],
      body: [
        [
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col1'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col2'),
          this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitRow1Col3'),
        ],
      ],
      didParseCell: (data: any) => {
        this.applySpansToMilkProductionUnitTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToMilkProductionUnitTable(data);
      },
    });
  }

  private applySpansToMilkProductionUnitTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 1) {
      data.cell.colSpan = 2;
    }
  }

  private addAcroFieldsToMilkProductionUnitTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 1) {
      const field = this.addAcroTextbox(data, { width: 25 });
      this.document.setFontSize(7.4);
      this.document.setFont('helvetica', 'italic');
      const positionX = field.x + field.width + this.separationSpace;
      const positionY = field.y + field.height - this.cellPaddingTop;
      this.document.text(
        this.translateService.instant('ProtocolPdf.GruyereMilkProductionUnitHeaderPart3'),
        positionX,
        positionY
      );
    }
    if (data.section === 'body' && data.row.index === 0) {
      const positionY = data.cell.y + this.cellPaddingTop;
      const textboxWidthCol1AndCol2 = 33;
      if (data.column.index === 0) {
        this.addAcroTextbox(
          data,
          { width: textboxWidthCol1AndCol2, y: positionY },
          this.protocolInspectionService.getCantonId()
        );
      }
      if (data.column.index === 1) {
        let labelTvdIdPositionX = this.calculateFieldPositionX(data);
        if (this.isCurrentLanguageFr) {
          labelTvdIdPositionX += this.separationSpace;
        }
        this.addAcroTextbox(
          data,
          { width: textboxWidthCol1AndCol2, x: labelTvdIdPositionX, y: positionY },
          this.protocolInspectionService.getTvdId()
        );
      }
      if (data.column.index === 2) {
        let textboxWidhtCol3 = 41.5;
        if (this.isCurrentLanguageFr) {
          textboxWidhtCol3 = 38;
        }
        this.addAcroTextbox(data, { width: textboxWidhtCol3, y: positionY });
      }
    }
  }

  private addRequirementsLegend(): number {
    this.setPagePositionY(0.7);
    const separationSpaceRow1 = 3.5;
    let resultSeparationSpace = separationSpaceRow1;
    let positionY = this.getPagePositionY() + separationSpaceRow1;
    let positionX = this.minPositionX + 1;
    this.document.setFontSize(10);
    this.document.setFont('helvetica', 'bold');
    this.document.text(
      this.translateService.instant('ProtocolPdf.GruyereRequirementsLegendRow1'),
      positionX,
      positionY
    );
    const separationSpaceRow2 = 7;
    resultSeparationSpace += separationSpaceRow2;
    positionY = this.getPagePositionY() + separationSpaceRow2;
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'bold');
    const row2Part1 = this.translateService.instant(
      'ProtocolPdf.GruyereRequirementsLegendRow2Part1'
    );
    this.document.text(row2Part1, positionX, positionY);
    positionX += this.document.getTextWidth(row2Part1) + 57;
    const row2Part2 = this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable');
    this.document.text(row2Part2, positionX, positionY);
    this.resetToDefaultFontSettings();
    const row2Part3 = this.translateService.instant(
      'ProtocolPdf.VacherinRequirementsLegendRow2Part2'
    );
    positionX += this.document.getTextWidth(row2Part2) + 2;
    this.document.text(row2Part3, positionX, positionY);
    return resultSeparationSpace;
  }

  private addRequirementsTableToPage1(separationSpaceLegend: number): void {
    const head = this.getRequirementsTableHeaderPage1();
    const body = this.getRequirementsTableBodyPage1();
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + separationSpaceLegend,
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: {
          cellWidth: this.requirementColumnArticleTextWidth,
          valign: 'middle',
          halign: 'center',
        },
        1: {
          cellWidth: this.requirementColumnArticleNumberWidth,
          valign: 'middle',
          halign: 'center',
        },
        2: { cellWidth: 6 },
        4: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
        5: {
          cellWidth: this.requirementColumnCheckboxNotWidth,
          valign: 'middle',
          halign: 'center',
        },
        6: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
      },
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTablePage1(data);
        this.applyStylesToRequirementsTablePage1(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToRequirementsTablePage1(data);
        this.drawCellBorderToRequirementsTablePage1(data);
      },
    });
  }

  private getRequirementsTableHeaderPage1(): any[][] {
    return [
      [
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3'
        ),
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable'),
      ],
    ];
  }

  private applySpansToRequirementsTablePage1(data: any): void {
    const columnIndexStartRequirement = 2;
    const columnIndexStartCheckboxes = 4;
    if (data.section === 'head') {
      if (data.row.index === 0 && data.column.index === 0) {
        data.cell.rowSpan = 2;
      }
    }
    const numberOfColumnsToSpanRequirement = 2;
    this.applySpansToHeaderRequirement(
      data,
      columnIndexStartCheckboxes,
      numberOfColumnsToSpanRequirement
    );
    this.applySpansToArticle10(data);
    this.applySpansToAllArticlesPage1(data, columnIndexStartRequirement);
  }

  private applySpansToAllArticlesPage1(data: any, columnIndexStartRequirement: number) {
    if (data.section === 'body') {
      if (
        data.row.index < columnIndexStartRequirement &&
        data.column.index === columnIndexStartRequirement
      ) {
        data.cell.colSpan = 2;
      }
    }
  }

  private applySpansToArticle10(data: any) {
    if (data.section === 'body') {
      if ((data.row.index === 3 || data.row.index === 5) && data.column.index === 2) {
        data.cell.colSpan = 2;
      }
      if (
        data.row.index === 3 &&
        (data.column.index === 0 || data.column.index === 1 || data.column.index >= 4)
      ) {
        data.cell.rowSpan = 3;
      }
      if (data.row.index === 3 && data.column.index === 2) {
        data.cell.colSpan = 2;
      }
    }
  }

  private applyStylesToRequirementsTablePage1(data: any): void {
    this.applyAlignmentInCenterToHeaderRequirement(data);
    this.applyPaddingToArticle10(data);
    this.applyFontsizeToColumnRequirement(data);
    if (data.section === 'body') {
      const columnIndexStartRequirement = 2;
      if (
        data.row.index < columnIndexStartRequirement &&
        data.column.index === columnIndexStartRequirement
      ) {
        data.cell.styles.halign = 'left';
      }
      if (
        data.row.index > 1 &&
        (data.column.index === columnIndexStartRequirement || data.column.index === 3)
      ) {
        this.removeCellBorder(data);
      }
    }
  }

  private applyPaddingToArticle10(data: any): void {
    if (data.section === 'body') {
      const defaultCellPadding = 1.2;
      const rowIndexArticle10Part1 = 3;
      const rowIndexArticle10Part2 = 4;
      const rowIndexArticle10Part3 = 5;
      if (data.row.index === rowIndexArticle10Part1) {
        data.cell.styles.cellPadding = {
          top: defaultCellPadding,
          right: defaultCellPadding,
          bottom: 0,
          left: defaultCellPadding,
        };
      }
      if (data.row.index === rowIndexArticle10Part2) {
        data.cell.styles.cellPadding = {
          top: 0,
          right: defaultCellPadding,
          bottom: 0,
          left: defaultCellPadding,
        };
      }
      if (data.row.index === rowIndexArticle10Part3) {
        data.cell.styles.cellPadding = {
          top: 0,
          right: defaultCellPadding,
          bottom: defaultCellPadding,
          left: defaultCellPadding,
        };
      }
    }
  }

  private addAcroFieldsToRequirementsTablePage1(data: any): void {
    const rowIndexArticle10Part1 = 3;
    const rowIndexArticle10Part3 = 5;
    const columnIndexStart = 3;
    this.addRequirementsCheckboxes(data, 3, [3]);
    this.addRequirementsSpanRowCheckboxes(
      data,
      rowIndexArticle10Part1,
      rowIndexArticle10Part3,
      columnIndexStart
    );
    const columnIndexStartRequirement = 2;
    if (
      data.section === 'body' &&
      data.row.index === 1 &&
      data.column.index === columnIndexStartRequirement
    ) {
      this.document.setFontSize(8.2);
      const field = this.addAcroTextbox(data, { width: 7.5 });
      this.document.setFont('helvetica', 'italic');
      const positionX = field.x + field.width + this.separationSpace;
      const positionY = field.y + field.height - this.cellPaddingTop;
      this.document.text(
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle8Part2'),
        positionX,
        positionY
      );
      this.resetToDefaultFontSettings();
    }
  }

  private drawCellBorderToRequirementsTablePage1(data: any): void {
    const isRequirementColumn = data.column.index === 2 || data.column.index === 3;
    const rowIndexArticle9 = 2;
    const rowIndexStartArticle10 = 3;
    const rowIndexEndArticle10 = 5;
    if (data.section === 'body') {
      if (
        (data.row.index === rowIndexArticle9 || data.row.index > rowIndexEndArticle10) &&
        isRequirementColumn
      ) {
        this.drawCellTopBorder(data);
        this.drawCellBottomBorder(data);
      }
      if (data.row.index === rowIndexStartArticle10 && isRequirementColumn) {
        this.drawCellTopBorder(data);
      }
      if (data.row.index === 4 && data.column.index === 3) {
        this.drawCellRightBorder(data);
      }
      if (data.row.index >= rowIndexArticle9 && data.column.index === 2) {
        this.drawCellLeftBorder(data);
      }
    }
  }

  private getRequirementsTableBodyPage1(): any[][] {
    return [
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle3'),
        '3',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle3'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle8'),
        '8',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle8Part1'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle9'),
        '9',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle9Numbers'),
        this.getTextArticle9(),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle10'),
        '10',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle10'),
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle10Numbers'),
        this.getTextArticle10(),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle10Part2'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle11'),
        '11',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle11Numbers'),
        this.getTextArticle11(),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle12'),
        '12',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle12Numbers'),
        this.getTextArticle12(),
        '',
        '',
        '',
      ],
    ];
  }

  private getTextArticle9(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number1Part1') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number1A') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number1B') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number1C') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number1D') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle9Number2Part1')
    );
  }

  private getTextArticle10(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle10A') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle10B')
    );
  }

  private getTextArticle11(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Number1') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Number2') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Number3') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle11Number4')
    );
  }

  private getTextArticle12(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number1') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number1A') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number1B') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number1C') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number1D') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle12Number2')
    );
  }

  private addRequirementsTableToPage2(): void {
    const head = this.getRequirementsTableHeaderPage2();
    const body = this.getRequirementsTableBodyPage2();
    const rowsWithList = [0, 1, 8, 9];
    //@ts-ignore
    this.document.autoTable({
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      margin: { top: this.pageHeaderHeight },
      columnStyles: {
        0: {
          cellWidth: this.requirementColumnArticleTextWidth,
          valign: 'middle',
          halign: 'center',
        },
        1: {
          cellWidth: this.requirementColumnArticleNumberWidth,
          valign: 'middle',
          halign: 'center',
        },
        2: { cellWidth: 6 },
        4: { cellWidth: 24 },
        5: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
        6: {
          cellWidth: this.requirementColumnCheckboxNotWidth,
          valign: 'middle',
          halign: 'center',
        },
        7: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
      },
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTablePage2(data);
        this.applyStylesToRequirementsTablePage2(data, rowsWithList);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToRequirementsTablePage2(data);
        this.drawCellBorderToRequirementsTablePage2(data, rowsWithList);
      },
    });
  }

  private getRequirementsTableHeaderPage2(): any[][] {
    return [
      [
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2'
        ),
        '',
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3'
        ),
        '',
        '',
      ],
      [
        '',
        '',
        '',
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable'),
      ],
    ];
  }

  private applySpansToRequirementsTablePage2(data: any): void {
    const columnIndexStartRequirement = 2;
    const columnIndexStartCheckboxes = 5;
    const numberOfColumnsToSpanRequirement = 3;
    this.applySpansToHeaderRequirement(
      data,
      columnIndexStartCheckboxes,
      numberOfColumnsToSpanRequirement
    );
    if (data.section === 'body') {
      const columnIndexEndRequirement = 4;
      this.applySpansToArticle7(data, columnIndexStartRequirement, columnIndexEndRequirement);
      this.applySpansToArticle18(data, columnIndexStartRequirement);
    }
  }

  private applySpansToArticle7(
    data: any,
    columnIndexStartRequirement: number,
    columnIndexEndRequirement: number
  ): void {
    const rowIndexArticle7Part1 = 2;
    const rowIndexArticle7Part2 = 3;
    const rowIndexArticle7Part3 = 4;
    const rowIndexArticle18 = 7;
    if (
      (data.row.index < rowIndexArticle7Part1 || data.row.index === rowIndexArticle7Part2) &&
      data.column.index === 3
    ) {
      data.cell.colSpan = 2;
    }
    if (
      (data.row.index === rowIndexArticle7Part1 || data.row.index === rowIndexArticle7Part3) &&
      (data.column.index < columnIndexStartRequirement ||
        data.column.index > columnIndexEndRequirement)
    ) {
      data.cell.rowSpan = 3;
    }
    if (
      (data.row.index === rowIndexArticle7Part1 || data.row.index > rowIndexArticle7Part2) &&
      data.row.index < rowIndexArticle18 &&
      data.column.index === columnIndexStartRequirement
    ) {
      data.cell.colSpan = 3;
    }
  }

  private applySpansToArticle18(data: any, columnIndexStartRequirement: number): void {
    const rowArticle18 = 7;
    if (data.row.index === rowArticle18 && data.column.index < columnIndexStartRequirement) {
      data.cell.rowSpan = 3;
    }
    if (data.row.index === rowArticle18 && data.column.index === columnIndexStartRequirement) {
      data.cell.colSpan = 2;
      if (this.isCurrentLanguageFr) {
        data.row.height += 9;
      }
    }
  }

  private applyStylesToRequirementsTablePage2(data: any, rowsWithList: number[]): void {
    this.applyAlignmentInCenterToHeaderRequirement(data);
    this.applyFontsizeToColumnRequirement(data);
    if (data.section === 'body') {
      const rowIndexArticle7 = 2;
      const columnIndexStartRequirement = 2;
      if (data.row.index < rowIndexArticle7 && data.column.index === columnIndexStartRequirement) {
        data.cell.styles.halign = 'left';
      }
      const rowsToRemoveBorder = rowsWithList;
      const rowToRemoveBorder = rowsToRemoveBorder.find((rowIndex) => rowIndex === data.row.index);
      if (
        rowToRemoveBorder != null &&
        (data.column.index === columnIndexStartRequirement || data.column.index === 3)
      ) {
        this.removeCellBorder(data);
      }
      this.applyStylesToArticle7(data, columnIndexStartRequirement);
      this.applyStylesToArticle18(data, columnIndexStartRequirement);
    }
  }

  private applyStylesToArticle7(data: any, columnIndexStartRequirement: number): void {
    const rowIndexArticle7Part1 = 2;
    const rowIndexArticle7Part2 = 3;
    const rowIndexArticle7Part3 = 4;
    const defaultCellPadding = 1.2;
    if (
      data.row.index === rowIndexArticle7Part1 &&
      data.column.index === columnIndexStartRequirement
    ) {
      data.cell.styles.cellPadding = {
        top: 1.5,
        right: defaultCellPadding,
        bottom: 0,
        left: defaultCellPadding,
      };
      this.removeCellBorder(data);
    }
    if (
      data.row.index === rowIndexArticle7Part2 &&
      (data.column.index === columnIndexStartRequirement || data.column.index === 3)
    ) {
      data.cell.styles.cellPadding = {
        top: 0,
        right: defaultCellPadding,
        bottom: 0,
        left: defaultCellPadding,
      };
      data.cell.styles.fontStyle = 'italic';
      this.removeCellBorder(data);
    }
    if (
      data.row.index === rowIndexArticle7Part3 &&
      data.column.index === columnIndexStartRequirement
    ) {
      data.cell.styles.cellPadding = {
        top: 0,
        right: defaultCellPadding,
        bottom: defaultCellPadding,
        left: defaultCellPadding,
      };
      data.cell.styles.fontStyle = 'italic';
      data.cell.styles.halign = 'center';
      this.removeCellBorder(data);
    }
  }

  private applyStylesToArticle18(data: any, columnIndexStartRequirement: number): void {
    const rowIndexArticle16 = 6;
    const rowIndexArticle18Part1 = 7;
    if (
      data.row.index === rowIndexArticle18Part1 &&
      data.column.index === columnIndexStartRequirement &&
      data.row.index === rowIndexArticle16
    ) {
      data.cell.styles.fontStyle = 'italic';
    }
    if (
      (data.row.index === rowIndexArticle18Part1 &&
        data.column.index === columnIndexStartRequirement) ||
      (data.row.index >= rowIndexArticle18Part1 && data.column.index === 4)
    ) {
      data.cell.styles.fontStyle = 'italic';
    }
  }

  private addAcroFieldsToRequirementsTablePage2(data: any): void {
    this.addRequirementsCheckboxes(data, 4, [2]);
    if (data.section === 'body') {
      const rowIndexArticle7Part1 = 2;
      const rowIndexArticle7part3 = 4;
      const columnIndexStart = 4;
      this.addRequirementsSpanRowCheckboxes(
        data,
        rowIndexArticle7Part1,
        rowIndexArticle7part3,
        columnIndexStart
      );
      this.addAcroFieldsToArticle15(data);
      this.addAcroFieldsToArticle18(data);
    }
  }

  private addRequirementsSpanRowCheckboxes(
    data: any,
    rowIndexStart: number,
    rowIndexEnd: number,
    columnIndexStart: number
  ): void {
    if (data.row.index === rowIndexStart && data.column.index > columnIndexStart) {
      const spanRowsHeighth = this.getSpanRowsHeight(data, rowIndexStart, rowIndexEnd);
      this.addCheckboxAlignCenter(data, spanRowsHeighth);
    }
  }

  private addAcroFieldsToArticle15(data: any): void {
    const rowIndexArticle15 = 5;
    const columnIndexRequirement = 2;
    if (data.row.index === rowIndexArticle15 && data.column.index === columnIndexRequirement) {
      this.addAcroTextbox(data, { width: 45, broaderMarginLeft: 0.5 });
    }
  }

  private addAcroFieldsToArticle18(data: any) {
    const rowIndexArticle18Part1 = 7;
    const rowIndexArticle18Part2 = 8;
    const rowIndexArticle18Part3 = 9;
    if (data.row.index === rowIndexArticle18Part1 && data.column.index === 2) {
      let positionX = this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part2')
      );
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part3'),
        { x: positionX }
      );
      const positionY = data.cell.y + 5;
      positionX = data.cell.x + data.cell.styles.cellPadding;
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part4'),
        { x: positionX, y: positionY }
      );
    }
    if (data.row.index === rowIndexArticle18Part2 && data.column.index === 4) {
      const positionX = data.cell.x + data.cell.styles.cellPadding;
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row2Col4'),
        { x: positionX }
      );
    }
    if (data.row.index === rowIndexArticle18Part3 && data.column.index === 4) {
      const positionX = data.cell.x + data.cell.styles.cellPadding;
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row3Col4'),
        { x: positionX }
      );
    }
  }

  private drawCellBorderToRequirementsTablePage2(data: any, rowsWithList: number[]): void {
    const rowsToAddBorder = rowsWithList;
    const rowToAddBorder = rowsToAddBorder.find((rowIndex) => rowIndex === data.row.index);
    if (data.section === 'body') {
      if (rowToAddBorder != null) {
        this.drawCellTopBorder(data);
        this.drawCellBottomBorder(data);
      }
      this.drawCellBorderArticle7(data);
      if ((data.row.index < 2 || data.row.index > 7) && data.column.index === 2) {
        this.drawCellLeftBorder(data);
      }
    }
  }

  private drawCellBorderArticle7(data: any): void {
    const rowIndexArticle7Part1 = 2;
    const rowIndexArticle18Part2 = 3;
    const rowIndexArticle14 = 1;
    const rowIndexArticle15 = 5;
    const columnIndexRequirementPart1 = 2;
    const columnIndexRequirementPart2 = 3;
    if (
      data.row.index === rowIndexArticle7Part1 &&
      data.column.index === columnIndexRequirementPart1
    ) {
      this.drawCellTopBorder(data);
    }
    if (
      data.row.index === rowIndexArticle18Part2 &&
      data.column.index === columnIndexRequirementPart2
    ) {
      this.drawCellRightBorder(data);
    }
    if (
      data.row.index > rowIndexArticle14 &&
      data.row.index < rowIndexArticle15 &&
      data.column.index === columnIndexRequirementPart1
    ) {
      this.drawCellLeftBorder(data);
    }
  }

  private getRequirementsTableBodyPage2(): any[][] {
    return [
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle13'),
        '13',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle13Numbers'),
        this.getTextArticle13(),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle14'),
        '14',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle14Numbers'),
        this.getTextArticle14(),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle7'),
        '7',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7Part1'),
        '',
        '',
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle7Row2Numbers'),
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7Part2'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle7Part3'),
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle15'),
        '15',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsText15Part1'),
        '',
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle16'),
        '16',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle16'),
        '',
        '',
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle18'),
        '18',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part1'),
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row1Col4'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        '1.',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row2Col3'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTitleArticle18Row3Numbers'),
        this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle18Row3Col3'),
        '',
        '',
        '',
      ],
    ];
  }

  private getTextArticle13(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number1') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number1A') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number1B') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number1C') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number1D') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle13Number2')
    );
  }

  private getTextArticle14(): void {
    return (
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle14Number1') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle14Number2') +
      this.translateService.instant('ProtocolPdf.GruyereRequirementsTextArticle14Number3')
    );
  }

  private addAnnexTable(): void {
    const separationSpaceToCreateTableInNewPage = 75;
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + separationSpaceToCreateTableInNewPage,
      margin: { top: this.pageHeaderHeight },
      styles: { fontSize: this.fontSize },
      body: [
        [
          this.translateService.instant(
            this.translateService.instant('ProtocolPdf.GruyereAnnexRow1Title')
          ),
        ],
        [
          this.translateService.instant(
            this.translateService.instant('ProtocolPdf.GruyereAnnexRow2Title')
          ),
        ],
      ],
      didParseCell: (data: any) => {
        this.applyStyleToAnnexTablePage3(data);
      },
    });
  }

  private addPerYearTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: { lineColor: ProtocolPdfConstants.whiteColor },
        1: {
          cellWidth: 30,
          fillColor: ProtocolPdfConstants.grayColor,
          valign: 'middle',
          halign: 'center',
        },
        2: {
          cellWidth: 30,
          fillColor: ProtocolPdfConstants.grayColor,
          valign: 'middle',
          halign: 'center',
        },
      },
      bodyStyles: { minCellHeight: 2 },
      headStyles: { minCellHeight: 2 },
      body: [
        [
          this.translateService.instant(
            this.translateService.instant('ProtocolPdf.GruyereAnnexRow3Cell1')
          ),
          this.translateService.instant(
            this.translateService.instant('ProtocolPdf.GruyereAnnexRow3Cell2')
          ),
          this.translateService.instant(
            this.translateService.instant('ProtocolPdf.GruyereAnnexRow3Cell3')
          ),
        ],
        ['', '', ''],
      ],
      didParseCell: (data: any) => {
        this.applySpansToPerYearTablePage3(data);
      },
      didDrawCell: (data: any) => {
        this.addTextboxesPage3(data, 1, 0);
      },
    });
  }

  private applyStyleToAnnexTablePage3(data: any): void {
    if (data.row.index === 0) {
      data.cell.styles.fontStyle = 'bold';
      data.cell.styles.lineColor = ProtocolPdfConstants.whiteColor;
      data.cell.styles.minCellHeight = 7.5;
    }
    if (data.row.index === 1) {
      data.cell.styles.fillColor = ProtocolPdfConstants.grayColor;
    }
  }

  private applySpansToPerYearTablePage3(data: any): void {
    if (data.row.index === 0 && data.column.index === 0) {
      data.cell.rowSpan = 2;
    }
    if (data.row.index === 1 && data.column.index > 0) {
      data.cell.styles.fillColor = [256, 256, 256];
    }
  }

  private addTextboxesPage3(
    data: any,
    startRowIndex: number,
    insertTextboxesAfterCellIndex: number
  ): void {
    if (
      data.section === 'body' &&
      data.row.index >= startRowIndex &&
      data.column.index > insertTextboxesAfterCellIndex
    ) {
      this.addAcroTextbox(data, { width: 25 });
    }
  }

  private addTotalConsumptionTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: { cellWidth: 7, valign: 'middle', halign: 'center' },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
      },
      headStyles: { fillColor: ProtocolPdfConstants.grayColor, minCellHeight: 2 },
      bodyStyles: { minCellHeight: 2 },
      head: [
        ['', this.translateService.instant('ProtocolPdf.GruyereTotalConsumptionHeader'), '', ''],
      ],
      body: [
        ['1', this.translateService.instant('ProtocolPdf.GruyereTotalConsumptionRow1'), '', ''],
        ['2', this.translateService.instant('ProtocolPdf.GruyereTotalConsumptionRow2'), '', ''],
        ['3', this.translateService.instant('ProtocolPdf.GruyereTotalConsumptionRow3'), '', ''],
      ],
      didParseCell: (data: any) => {
        this.applyGrayColorToRow(data, 2);
      },
      didDrawCell: (data: any) => {
        this.addTextboxesPage3(data, 0, 1);
      },
    });
  }

  private addPurchasesTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: { cellWidth: 7, valign: 'middle', halign: 'center' },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
      },
      headStyles: { fillColor: ProtocolPdfConstants.grayColor, minCellHeight: 2 },
      bodyStyles: { minCellHeight: 2 },
      head: [['', this.translateService.instant('ProtocolPdf.GruyerePurchasesHeader'), '', '']],
      body: [
        ['4', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow1'), '', ''],
        ['5', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow2'), '', ''],
        ['6', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow3'), '', ''],
        ['7', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow4'), '', ''],
        ['8', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow5'), '', ''],
        ['9', this.translateService.instant('ProtocolPdf.GruyerePurchasesRow6'), '', ''],
      ],
      didParseCell: (data: any) => {
        this.applyGrayColorToRow(data, 5);
      },
      didDrawCell: (data: any) => {
        this.addTextboxesPage3(data, 0, 1);
      },
    });
  }

  private addProportionTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      styles: { fontSize: this.fontSize, fillColor: ProtocolPdfConstants.grayColor },
      columnStyles: {
        0: { cellWidth: 7, valign: 'middle', halign: 'center' },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
      },
      headStyles: { fillColor: ProtocolPdfConstants.grayColor, minCellHeight: 2 },
      bodyStyles: { minCellHeight: 2 },
      head: [['10', this.translateService.instant('ProtocolPdf.GruyereProportionRow1'), '', '']],
      body: [['11', this.translateService.instant('ProtocolPdf.GruyereProportionRow2'), '', '']],
      didParseCell: (data: any) => {
        this.applySpansToProportionTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToProportionTablePage3(data);
      },
    });
  }

  private applySpansToProportionTable(data: any): void {
    if (data.section === 'body' && data.row.index === 0 && data.column.index === 2) {
      data.cell.colSpan = 2;
    }
  }

  private addAcroFieldsToProportionTablePage3(data: any): void {
    if (data.column.index > 1) {
      const hasLargeTextbox =
        data.section === 'body' && data.row.index === 0 && data.column.index === 2;
      const textboxWidth = hasLargeTextbox ? 55 : 25;
      this.addAcroTextbox(data, { width: textboxWidth });
    }
  }

  private applyGrayColorToRow(data: any, rowIndex: number): void {
    if (data.section === 'body' && data.row.index === rowIndex) {
      data.cell.styles.fillColor = ProtocolPdfConstants.grayColor;
    }
  }
}
