/* eslint-disable @typescript-eslint/naming-convention */

import { ProtocolPdfFrMilkProducersService } from './protocol-pdf-fr-milk-producers-service';
import { ProtocolPdfConstants } from './protocol-pdf.constants';

export class ProtocolPdfFrVacherinService extends ProtocolPdfFrMilkProducersService {
  protected pageFooterDateAsText = '07.06.2021';
  protected translationKeyPageHeaderTitle = 'ProtocolPdf.VacherinPageHeader';
  protected prefixFileName = 'VFR';

  protected addTablesToPages(): void {
    this.addTablesToPage1();
  }

  protected getTranslationKeyPageFooterRow2Part1(): string {
    return 'ProtocolPdf.VacherinPageFooterRow2Part1';
  }

  private addTablesToPage1(): void {
    this.addUnitTable();
    this.addAuditorTable();
    this.addTvdTable();
    this.addMilkProducersTable(
      this.translateService.instant('ProtocolPdf.VacherinAndGruyereTextHeader'),
      4
    );
    let legendSpaceY = this.addRequirementsLegend();
    this.addRequirementsTable(legendSpaceY);
    legendSpaceY = this.addRemarksLegend();
    let numberOfRows = 4;
    if (this.isCurrentLanguageFr) {
      numberOfRows = 3;
    }
    this.addRemarksTable(legendSpaceY, numberOfRows);
    this.addConfirmationTable();
  }

  private addTvdTable(): void {
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + this.tableSeparationSpace,
      columnStyles: {
        0: { cellWidth: 59 },
        1: { cellWidth: 59 },
        2: { cellWidth: 63.5 },
      },
      headStyles: {
        fillColor: ProtocolPdfConstants.whiteColor,
      },
      head: [
        [
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol1'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol2'),
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereTVDHeaderCol3Part1'),
        ],
      ],
      body: [
        ['', '', ''],
        [this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part1'), '', ''],
      ],
      didParseCell: (data: any) => {
        this.applySpansToTvdTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToTvdTable(data);
      },
    });
  }

  private applySpansToTvdTable(data: any): void {
    if (data.section === 'body' && data.row.index === 1 && data.column.index === 0) {
      data.cell.colSpan = 3;
    }
  }

  private addAcroFieldsToTvdTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 2) {
      const positionX = this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes')
      );
      this.addAcroCheckbox(
        data,
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        { x: positionX }
      );
    }
    if (data.section === 'body') {
      if (data.row.index === 0) {
        const textboxWidth = 50;
        if (data.column.index === 0) {
          this.addAcroTextbox(
            data,
            { width: textboxWidth },
            this.protocolInspectionService.getTvdId()
          );
        } else {
          this.addAcroTextbox(data, { width: textboxWidth });
        }
      }
      if (data.row.index === 1 && data.column.index === 0) {
        let positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part2')
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part3'),
          { x: positionX }
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinTvdRow2Col1Part4'),
          { x: positionX }
        );
        this.addAcroTextbox(data, { width: 25, x: positionX });
      }
    }
  }

  private addRequirementsLegend(): number {
    this.setPagePositionY(0.7);
    const firstLineSpace = 3.5;
    let resultLegendSpaceY = firstLineSpace;
    let positionY = this.getPagePositionY() + firstLineSpace;
    let positionX = 16;
    this.document.setFontSize(10);
    this.document.setFont('helvetica', 'bold');
    this.document.text(
      this.translateService.instant('ProtocolPdf.VacherinRequirementsLegendRow1'),
      positionX,
      positionY
    );
    const secondLineSpace = 6.5;
    resultLegendSpaceY += secondLineSpace;
    positionY = this.getPagePositionY() + secondLineSpace;
    this.document.setFontSize(this.fontSize);
    this.document.setFont('helvetica', 'bold');
    const row2Part1 = this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable');
    this.document.text(row2Part1, positionX, positionY);
    positionX += this.document.getTextWidth(row2Part1) + 2;
    this.document.setFont('helvetica', 'normal');
    const row2Part2 = this.translateService.instant(
      'ProtocolPdf.VacherinRequirementsLegendRow2Part2'
    );
    this.document.text(row2Part2, positionX, positionY);
    return resultLegendSpaceY - this.tableSeparationSpace;
  }

  private addRequirementsTable(legendSpaceY: number): void {
    const head = this.getRequirementsTableHeader();
    const body = this.getRequirementsTableBody();
    const checkboxCellWidth = 7.5;
    //@ts-ignore
    this.document.autoTable({
      startY: this.getPagePositionY() + legendSpaceY,
      headStyles: { halign: 'center', fontStyle: 'bold' },
      styles: { fontSize: this.fontSize },
      columnStyles: {
        0: {
          cellWidth: this.requirementColumnArticleTextWidth,
          valign: 'middle',
          halign: 'center',
        },
        1: {
          cellWidth: this.requirementColumnArticleNumberWidth,
          valign: 'middle',
          halign: 'center',
        },
        3: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
        4: {
          cellWidth: this.requirementColumnCheckboxNotWidth,
          valign: 'middle',
          halign: 'center',
        },
        5: { cellWidth: this.requirementColumnCheckboxWidth, valign: 'middle', halign: 'center' },
      },
      head,
      body,
      didParseCell: (data: any) => {
        this.applySpansToRequirementsTable(data);
        this.applyAlignmentsToRequirementsTable(data);
      },
      didDrawCell: (data: any) => {
        this.addAcroFieldsToRequirementsTable(data);
      },
    });
  }

  private getRequirementsTableHeader(): any[][] {
    return [
      [
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1'
        ),
        '',
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2'
        ),
        this.translateService.instant(
          'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3'
        ),
        '',
        '',
      ],
      [
        '',
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot'),
        this.translateService.instant('ProtocolPdf.VacherinAndGruyereNotApplicable'),
      ],
    ];
  }

  private applySpansToRequirementsTable(data: any): void {
    if (data.section === 'head' && data.row.index === 0 && data.column.index === 0) {
      data.cell.colSpan = 2;
    }
    const rowIndexArticle8And9 = 8;
    const rowIndexArticle7 = 4;
    const columnIndexStartCheckboxes = 3;
    const columnIndexRequirement = 2;
    const numberOfColumnsToSpanRequirement = 1;
    this.applySpansToHeaderRequirement(
      data,
      columnIndexStartCheckboxes,
      numberOfColumnsToSpanRequirement
    );
    this.applySpansToCheckboxesFromRequirements(data);
    if (data.section === 'body') {
      if (data.row.index === 8 && this.isCurrentLanguageFr) {
        data.row.height += 1.5;
      }
      if (data.row.index === 0 && data.column.index < columnIndexRequirement) {
        data.cell.rowSpan = 3;
      }
      this.applySpansRowsWithoutArticles(
        data,
        rowIndexArticle7,
        rowIndexArticle8And9,
        columnIndexRequirement
      );
      if (data.row.index > 11 && data.column.index < columnIndexRequirement) {
        data.cell.rowSpan = 3;
      }
    }
  }

  private applySpansToCheckboxesFromRequirements(data: any): void {
    const columnIndexRequirement = 2;
    const columnIndexStartCheckboxes = 3;
    if (data.section === 'body') {
      const rowIndexArticle8And9 = 8;
      const rowIndexArticle11 = 12;
      if (data.row.index === rowIndexArticle11 && data.column.index === 3) {
        data.cell.rowSpan = 2;
      }
      if (
        (data.row.index === rowIndexArticle8And9 || data.row.index === rowIndexArticle11) &&
        data.column.index === columnIndexStartCheckboxes
      ) {
        data.cell.colSpan = 3;
        data.cell.styles.fillColor = ProtocolPdfConstants.grayColor;
      }
      if (
        this.isCurrentLanguageFr &&
        data.row.index === rowIndexArticle8And9 &&
        data.column.index === columnIndexRequirement
      ) {
        data.cell.colSpan = 4;
        data.cell.styles.fillColor = ProtocolPdfConstants.whiteColor;
      }
    }
  }

  private applySpansRowsWithoutArticles(
    data: any,
    rowIndexArticle7: number,
    rowIndexArticle8And9: number,
    columnIndexRequirement: number
  ) {
    let rowSpanArticle8 = 3;
    if (this.isCurrentLanguageFr) {
      rowSpanArticle8 = 4;
    }
    if (
      data.row.index > rowIndexArticle7 &&
      data.row.index < rowIndexArticle8And9 &&
      data.column.index < columnIndexRequirement
    ) {
      data.cell.rowSpan = rowSpanArticle8;
    }
  }

  private applyAlignmentsToRequirementsTable(data: any): void {
    this.applyAlignmentInCenterToHeaderRequirement(data);
    this.applyFontsizeToColumnRequirement(data);
    if (data.section === 'body' && data.row.index === 14 && this.isCurrentLanguageFr) {
      data.cell.styles.fontSize = this.requirementFontSize - 0.75;
    }
    if (
      data.section === 'body' &&
      data.row.index > 8 &&
      data.row.index < 12 &&
      data.column.index === 0
    ) {
      data.cell.colSpan = 3;
      data.cell.styles.valign = 'top';
      data.cell.styles.halign = 'left';
      data.cell.styles.fontSize = this.requirementFontSize;
    }
  }

  private addAcroFieldsToRequirementsTable(data: any): void {
    let skipRows = [8, 12, 13];
    if (this.isCurrentLanguageFr) {
      skipRows = [8, 12];
    }
    this.addRequirementsCheckboxes(data, 2, skipRows);
    if (data.section === 'body' && data.column.index === 2) {
      if (data.row.index === 0) {
        let textboxWidth = 55;
        if (this.isCurrentLanguageFr) {
          textboxWidth = 75;
        }
        this.addAcroTextbox(data, { width: textboxWidth, broaderMarginLeft: -0.25 });
      }
      if (data.row.index === 4) {
        const field = this.addAcroTextbox(data, { width: 12.5 });
        const positionY = field.y + field.height - this.cellPaddingTop;
        const positionX = field.x + field.width + this.separationSpace;
        this.document.text('%', positionX, positionY);
      }
      if (data.row.index === 8) {
        const cellPaddingLeft = 1.5;
        let positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereNot')
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinAndGruyereYes'),
          { x: positionX }
        );
        positionX = data.cell.x + cellPaddingLeft;
        const positionY = data.cell.y + this.cellPaddingTop + this.lineHeight * 2;
        this.document.text(
          this.translateService.instant(
            'ProtocolPdf.VacherinRequirementsArticle8And9Column3Row1Part2'
          ),
          positionX,
          positionY
        );
      }
      if (data.row.index === 12) {
        this.addAcroTextbox(data, { width: 40 });
      }
      if (data.row.index === 13 || data.row.index === 14) {
        let positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part2')
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant(
            'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part3'
          ),
          { x: positionX }
        );
        positionX = this.addAcroCheckbox(
          data,
          this.translateService.instant(
            'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part4'
          ),
          { x: positionX }
        );
      }
    }
  }

  private getRequirementsTableBody(): any[][] {
    const article8 = this.isCurrentLanguageFr
      ? this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8And9')
      : '8';
    const bodyTable = [
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column1Row1'),
        '2',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column3Row1'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column3Row2'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle2Column3Row3'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle6Column1Row1'),
        '6',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle6Column3Row1'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle7Column1Row1'),
        '7',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle7Column3Row1'),
        '',
        '',
        '',
      ],
      [
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column1Row1'),
        article8,
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column3Row1'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column3Row2'),
        '',
        '',
        '',
      ],
      [
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column3Row3'),
        '',
        '',
        '',
      ],
    ];
    if (this.isCurrentLanguageFr) {
      bodyTable.push([
        '',
        '',
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8Column3Row4'),
        '',
        '',
        '',
      ]);
    } else {
      bodyTable.push([
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8And9Column1Row1'),
        this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle8And9Column2Row1'),
        this.translateService.instant(
          'ProtocolPdf.VacherinRequirementsArticle8And9Column3Row1Part1'
        ),
        '',
        '',
        '',
      ]);
    }
    bodyTable.push([
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle10Column1Row1'),
      '',
      '',
      '',
      '',
      '',
    ]);
    bodyTable.push([
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle10Column1Row2'),
      '',
      '',
      '',
      '',
      '',
    ]);
    bodyTable.push([
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle10Column1Row3'),
      '',
      '',
      '',
      '',
      '',
    ]);
    bodyTable.push([
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle11Column1Row1'),
      '11',
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle11Column3Row1'),
      '',
      '',
      '',
    ]);
    bodyTable.push(['', '', '', '', '', '']);
    bodyTable.push([
      '',
      '',
      this.translateService.instant('ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part1'),
      '',
      '',
      '',
    ]);
    return bodyTable;
  }
}
