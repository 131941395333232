import { keysToTranslate } from 'src/app/core/util/keys-to-translate';

export class ProtocolPdfConstants {
  public static readonly marginX = 24;
  public static readonly maxXValue = 200;
  public static readonly maxLineWidth =
    ProtocolPdfConstants.maxXValue - ProtocolPdfConstants.marginX;
  public static readonly marginYFirstPage = 11;
  public static readonly marginYFurtherPages = 30;
  public static readonly maxYValue = 280;
  public static readonly lineHeight = 4.8;
  public static readonly defaultFontSize = 11;
  public static readonly defaultFontType = 'normal';
  public static readonly tinyFontSize = 7;
  public static readonly smallFontSize = 9;
  public static readonly bigFontSize = 14;
  public static readonly boldFontType = 'bold';
  public static readonly fontType = 'helvetica';
  public static readonly italicFontType = 'italic';
  public static readonly firstColumnWidth = 47;
  public static readonly pointGroupIndentationPerLevel = 3;
  public static readonly defectsTableColumnWidthNumber = 9;
  public static readonly defectsTableColumnWidthDefect = 72;
  public static readonly defectsTableColumnWidthMeasure = 72;
  public static readonly defectsTableColumnWidthMeasureExtended =
    ProtocolPdfConstants.defectsTableColumnWidthMeasure + 43;
  public static readonly defectsTableColumnWidthGve = 25;
  public static readonly defectsTableColumnWidthDeadline =
    ProtocolPdfConstants.calculateDefectsTableColumnWidthDeadline();
  public static readonly defectsCorrectionTableColumnWidthNumber = 10;
  public static readonly defectsCorrectionTableColumnWidthDefect = 61;
  public static readonly defectsCorrectionTableColumnWidthMeasure = 58;
  public static readonly defectsCorrectionTableColumnWidthDeadline = 23;
  public static readonly defectsCorrectionTableColumnWidthCorrected =
    ProtocolPdfConstants.calculateDefectsCorrectionTableColumnWidthCorrected();
  public static readonly defectsTableMarginBetweenDefects = 1;
  public static readonly signatureBlockWidthLabel = 30;
  public static readonly signatureBlockWidthDottedLine =
    ProtocolPdfConstants.calculateSignatureBlockWidthDottedLine();
  // 284.9 mm (Footer Line) + 3.6 mm (Footer Text Basis bis Footer Line) + 8.5 mm (Seitenrand Unten CD Kanton Bern) = 297 mm (A4)
  public static readonly footerLineYValue = 284.9;
  public static readonly dateFormat = 'dd.MM.yyyy';
  public static readonly grayColor = [238, 238, 238];
  public static readonly whiteColor = [256, 256, 256];

  public constructor() {
    keysToTranslate([
      'AG_protocol_standard',
      'AG_protocol_bienen',
      'ARAI_protocol_standard',
      'ARAI_protocol_certificate',
      'BE_protocol_standard',
      'BE_protocol_dhbVertragskantone',
      'FL_protocol_standard',
      'FR_protocol_standard',
      'FR_protocol_viehhandel',
      'FR_protocol_gruyere',
      'FR_protocol_vacherin',
      'URK_protocol_standard',
      'ZH_protocol_standard',
      'ZH_protocol_standard_tnp',
      'ZH_protocol_privatepharmacies',
      'Fehler beim Laden des Bildes, pdf wird ohne Bild erzeugt',
      'labelKey',
      'ProtocolPdf.AiArCertificateForFailCheckbox1',
      'ProtocolPdf.AiArCertificateForFailCheckbox2',
      'ProtocolPdf.AiArCertificateForFailCheckboxesTitle',
      'ProtocolPdf.AiArCertificateForSuccessCheckbox1',
      'ProtocolPdf.AiArCertificateForSuccessCheckbox2',
      'ProtocolPdf.AiArCertificateForSuccessCheckbox3',
      'ProtocolPdf.AiArCertificateForSuccessCheckboxesTitle',
      'ProtocolPdf.DefectCorrectionTableHeaderCorrected',
      'ProtocolPdf.DefectCorrectionTableHeaderDeadline',
      'ProtocolPdf.DefectCorrectionTableHeaderDeadlineUntil',
      'ProtocolPdf.DefectCorrectionTableHeaderDefect',
      'ProtocolPdf.DefectCorrectionTableHeaderMeasure',
      'ProtocolPdf.DefectCorrectionTableHeaderNr',
      'ProtocolPdf.DefectTableHeaderProposedMeasure',
      'ProtocolPdf.DefectTableHeaderDeadline',
      'ProtocolPdf.DefectTableHeaderDefect',
      'ProtocolPdf.DefectTableHeaderGve',
      'ProtocolPdf.DefectTableHeaderMeasure',
      'ProtocolPdf.DefectTableHeaderNr',
      'ProtocolPdf.FieldValues',
      'ProtocolPdf.GruyereAnnexRow1Title',
      'ProtocolPdf.GruyereAnnexRow2Title',
      'ProtocolPdf.GruyereAnnexRow3Cell1',
      'ProtocolPdf.GruyereAnnexRow3Cell2',
      'ProtocolPdf.GruyereAnnexRow3Cell3',
      'ProtocolPdf.GruyereLegendRemarksTitle',
      'ProtocolPdf.GruyereMilkProducersHeader',
      'ProtocolPdf.GruyereMilkProductionUnitHeaderPart1',
      'ProtocolPdf.GruyereMilkProductionUnitHeaderPart2',
      'ProtocolPdf.GruyereMilkProductionUnitHeaderPart3',
      'ProtocolPdf.GruyereMilkProductionUnitRow1Col1',
      'ProtocolPdf.GruyereMilkProductionUnitRow1Col2',
      'ProtocolPdf.GruyereMilkProductionUnitRow1Col3',
      'ProtocolPdf.GruyerePageFooterRow2Part1',
      'ProtocolPdf.GruyerePageHeader',
      'ProtocolPdf.GruyerePurchasesHeader',
      'ProtocolPdf.GruyerePurchasesRow1',
      'ProtocolPdf.GruyerePurchasesRow2',
      'ProtocolPdf.GruyerePurchasesRow3',
      'ProtocolPdf.GruyerePurchasesRow4',
      'ProtocolPdf.GruyerePurchasesRow5',
      'ProtocolPdf.GruyerePurchasesRow6',
      'ProtocolPdf.GruyereProportionRow1',
      'ProtocolPdf.GruyereProportionRow2',
      'ProtocolPdf.GruyereRechtsformDesBetriebes',
      'ProtocolPdf.GruyereRequirementsLegendRow1',
      'ProtocolPdf.GruyereRequirementsLegendRow2Part1',
      'ProtocolPdf.GruyereRequirementsLegendRow2Part2',
      'ProtocolPdf.GruyereRequirementsText15Part1',
      'ProtocolPdf.GruyereRequirementsTextArticle3',
      'ProtocolPdf.GruyereRequirementsTextArticle16',
      'ProtocolPdf.GruyereRequirementsTextArticle7Part1',
      'ProtocolPdf.GruyereRequirementsTextArticle7Part2',
      'ProtocolPdf.GruyereRequirementsTextArticle7Part3',
      'ProtocolPdf.GruyereRequirementsTitleArticle7Row2Numbers',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number1A',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number1B',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number1C',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number1D',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number1Part1',
      'ProtocolPdf.GruyereRequirementsTextArticle9Number2Part1',
      'ProtocolPdf.GruyereRequirementsTitleArticle9Numbers',
      'ProtocolPdf.GruyereRequirementsTextArticle10',
      'ProtocolPdf.GruyereRequirementsTextArticle10A',
      'ProtocolPdf.GruyereRequirementsTextArticle10B',
      'ProtocolPdf.GruyereRequirementsTextArticle10Part2',
      'ProtocolPdf.GruyereRequirementsTextArticle11Number1',
      'ProtocolPdf.GruyereRequirementsTextArticle11Number2',
      'ProtocolPdf.GruyereRequirementsTextArticle11Number3',
      'ProtocolPdf.GruyereRequirementsTextArticle11Number4',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number1',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number1A',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number1B',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number1C',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number1D',
      'ProtocolPdf.GruyereRequirementsTextArticle12Number2',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number1',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number1A',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number1B',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number1C',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number1D',
      'ProtocolPdf.GruyereRequirementsTextArticle13Number2',
      'ProtocolPdf.GruyereRequirementsTextArticle14Number1',
      'ProtocolPdf.GruyereRequirementsTextArticle14Number2',
      'ProtocolPdf.GruyereRequirementsTextArticle14Number3',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part1',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part2',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part3',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row1Col3Part4',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row1Col4',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row2Col3',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row2Col4',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row3Col3',
      'ProtocolPdf.GruyereRequirementsTextArticle18Row3Col4',
      'ProtocolPdf.GruyereRequirementsTitleArticle3',
      'ProtocolPdf.GruyereRequirementsTitleArticle7',
      'ProtocolPdf.GruyereRequirementsTitleArticle8',
      'ProtocolPdf.GruyereRequirementsTitleArticle8Part1',
      'ProtocolPdf.GruyereRequirementsTitleArticle8Part2',
      'ProtocolPdf.GruyereRequirementsTitleArticle9',
      'ProtocolPdf.GruyereRequirementsTitleArticle10',
      'ProtocolPdf.GruyereRequirementsTitleArticle11',
      'ProtocolPdf.GruyereRequirementsTitleArticle12',
      'ProtocolPdf.GruyereRequirementsTitleArticle13',
      'ProtocolPdf.GruyereRequirementsTitleArticle14',
      'ProtocolPdf.GruyereRequirementsTitleArticle15',
      'ProtocolPdf.GruyereRequirementsTitleArticle16',
      'ProtocolPdf.GruyereRequirementsTitleArticle10Numbers',
      'ProtocolPdf.GruyereRequirementsTitleArticle11Numbers',
      'ProtocolPdf.GruyereRequirementsTitleArticle12Numbers',
      'ProtocolPdf.GruyereRequirementsTitleArticle13Numbers',
      'ProtocolPdf.GruyereRequirementsTitleArticle14Numbers',
      'ProtocolPdf.GruyereRequirementsTitleArticle18',
      'ProtocolPdf.GruyereRequirementsTitleArticle18Row3Numbers',
      'ProtocolPdf.GruyereTotalConsumptionHeader',
      'ProtocolPdf.GruyereTotalConsumptionRow1',
      'ProtocolPdf.GruyereTotalConsumptionRow2',
      'ProtocolPdf.GruyereTotalConsumptionRow3',
      'ProtocolPdf.HeaderConfirmationDefectCorrection',
      'ProtocolPdf.HeaderAddressBe',
      'ProtocolPdf.HeaderBe1',
      'ProtocolPdf.HeaderBe2',
      'ProtocolPdf.HeaderBe3',
      'ProtocolPdf.HeaderFr1',
      'ProtocolPdf.HeaderFr2',
      'ProtocolPdf.HeaderFr3',
      'ProtocolPdf.HeaderMailAddressBe',
      'ProtocolPdf.HeaderName',
      'ProtocolPdf.HeaderPhoneNumberBe',
      'ProtocolPdf.HeaderPostalZoneBe',
      'ProtocolPdf.HeaderProtocol',
      'ProtocolPdf.HeaderReport',
      'ProtocolPdf.HeaderReportArAiCertificate',
      'ProtocolPdf.HeaderVetReport',
      'ProtocolPdf.HeaderVetReport2',
      'ProtocolPdf.HeaderWebsiteBe',
      'ProtocolPdf.HeaderZipCodeAndTownBe',
      'ProtocolPdf.LabelAbbreviations',
      'ProtocolPdf.LabelAbsent',
      'ProtocolPdf.LabelAddress',
      'ProtocolPdf.LabelBetriebZusaetzlicheAngaben',
      'ProtocolPdf.LabelBURNr',
      'ProtocolPdf.LabelCommentOfDepartment',
      'ProtocolPdf.LabelCommentOfDepartmentShort',
      'ProtocolPdf.LabelCommentOfDepartmentShortPlural',
      'ProtocolPdf.LabelControlledArea',
      'ProtocolPdf.LabelControlledLocation',
      'ProtocolPdf.LabelCount',
      'ProtocolPdf.LabelDate',
      'ProtocolPdf.LabelDefectCorrectionRemarks',
      'ProtocolPdf.LabelDefectCorrectionRemarksUnit',
      'ProtocolPdf.LabelDocumentationOther',
      'ProtocolPdf.LabelDocumentationPhotos',
      'ProtocolPdf.LabelDocumentationPhotosAdded',
      'ProtocolPdf.LabelDuration',
      'ProtocolPdf.LabelEmail',
      'ProtocolPdf.LabelEmailD',
      'ProtocolPdf.LabelEmailSAAV',
      'ProtocolPdf.LabelErfassterWert',
      'ProtocolPdf.LabelFee',
      'ProtocolPdf.LabelFurtherAction',
      'ProtocolPdf.LabelHours',
      'ProtocolPdf.LabelInspectionID',
      'ProtocolPdf.LabelInspectionAsanID',
      'ProtocolPdf.LabelInspectionResult',
      'ProtocolPdf.LabelKTID',
      'ProtocolPdf.LabelLocation',
      'ProtocolPdf.LabelName',
      'ProtocolPdf.LabelOrderLSVW',
      'ProtocolPdf.LabelPhone',
      'ProtocolPdf.LabelPosition',
      'ProtocolPdf.LabelPostalZoneTown',
      'ProtocolPdf.LabelReason',
      'ProtocolPdf.LabelSignature',
      'ProtocolPdf.LabelSignatureInspectionPerson',
      'ProtocolPdf.LabelSignatureInspectionValidWithoutSignature',
      'ProtocolPdf.LabelSignatureInspectionPersonDescriptionPrivatePharmaciesZh',
      'ProtocolPdf.LabelSignatureInspectionPersonTitlePrivatePharmaciesZh',
      'ProtocolPdf.LabelSignatureRejected',
      'ProtocolPdf.LabelSignatureResponsiblePerson',
      'ProtocolPdf.LabelSignatureResponsiblePersonDescriptionPrivatePharmaciesZh',
      'ProtocolPdf.LabelSignatureResponsiblePersonTitlePrivatePharmaciesZh',
      'ProtocolPdf.LabelStatement',
      'ProtocolPdf.LabelStatementUrkantone',
      'ProtocolPdf.LabelTVDNr',
      'ProtocolPdf.LabelTotal',
      'ProtocolPdf.LabelTotalChf',
      'ProtocolPdf.LabelType',
      'ProtocolPdf.LabelUnitAbsent',
      'ProtocolPdf.LabelUnitAbsentDeposit',
      'ProtocolPdf.LabelUnitAbsentDepositSimple',
      'ProtocolPdf.LabelUnitAbsentDepositMailOrLetterSimple',
      'ProtocolPdf.LabelUnitAbsentEmailSimple',
      'ProtocolPdf.LabelUnitAbsentLetter',
      'ProtocolPdf.LabelUnitAbsentLetterSimple',
      'ProtocolPdf.LabelBetriebBasisdaten',
      'ProtocolPdf.LabelUnitTnpAbsent',
      'ProtocolPdf.LabelUnitCopy',
      'ProtocolPdf.LabelUnitCopyPending',
      'ProtocolPdf.LabelUnitFromLocation',
      'ProtocolPdf.PageNumber',
      'ProtocolPdf.SublabelInspector',
      'ProtocolPdf.SublabelInspectorD',
      'ProtocolPdf.SublabelInspectorFiller',
      'ProtocolPdf.SublabelResponsiblePerson',
      'ProtocolPdf.Statement',
      'ProtocolPdf.TextAbbreviationAln',
      'ProtocolPdf.TextAbbreviationVeta',
      'ProtocolPdf.TextAln',
      'ProtocolPdf.TextConfirmationDeadlineAiAr',
      'ProtocolPdf.TextConfirmationDeadlineAg',
      'ProtocolPdf.TextConfirmationDeadlineBe',
      'ProtocolPdf.TextConfirmationDeadlineBe.DhbVertragskantone',
      'ProtocolPdf.TextConfirmationDeadlineFl',
      'ProtocolPdf.TextConfirmationDeadlineFr',
      'ProtocolPdf.TextConfirmationDeadlineUrk',
      'ProtocolPdf.TextConfirmationDeadlineZh',
      'ProtocolPdf.TextConfirmationDefectCorrection',
      'ProtocolPdf.TextConfirmationDefectCorrectionWithDate',
      'ProtocolPdf.TextDefectsAccordingToList',
      'ProtocolPdf.TextHeaderFurtherProcedureDetails',
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsArAi',
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsAg',
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsFl',
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsUrk',
      'ProtocolPdf.TextHeaderFurtherProcedureDetailsZh',
      'ProtocolPdf.TextHeaderOptionsForComment',
      'ProtocolPdf.TextMeasureAccordingToList',
      'ProtocolPdf.TextNoDefects',
      'ProtocolPdf.TextOptionForComment1',
      'ProtocolPdf.TextOptionForComment1.DhbVertragskantone',
      'ProtocolPdf.TextOptionForComment2.DhbVertragskantone',
      'ProtocolPdf.TextOptionForComment2',
      'ProtocolPdf.TextOptionFurtherProcedureDetails1',
      'ProtocolPdf.TextOptionFurtherProcedureDetails1.DhbVertragskantone',
      'ProtocolPdf.TextOptionFurtherProcedureDetails2',
      'ProtocolPdf.TextOptionFurtherProcedureDetails2.DhbVertragskantone',
      'ProtocolPdf.TextOptionFurtherProcedureDetails3',
      'ProtocolPdf.TextOptionFurtherProcedureDetails3.DhbVertragskantone',
      'ProtocolPdf.TextOptionFurtherProcedureDetails4',
      'ProtocolPdf.TextOptionFurtherProcedureDetails4.DhbVertragskantone',
      'ProtocolPdf.TextOptionFurtherProcedureDetails5.DhbVertragskantone',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsArAi1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsArAi2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsArAi3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsArAi4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg5',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg6',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsAg7',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFl1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFl2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFl3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFl4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFr1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFr2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFr3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsFr4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk5',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsUrk6',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZh1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZh2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZh3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZh4',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsZhTnp3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh1',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh2',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh3',
      'ProtocolPdf.TextOptionFurtherProcedureDetailsPrivatePharmaciesZh4',
      'ProtocolPdf.TextOrderLSVW1',
      'ProtocolPdf.TextOrderLSVW2',
      'ProtocolPdf.TextResponsiblePersonPresent',
      'ProtocolPdf.TextResponsiblePersonPresentFr',
      'ProtocolPdf.TextResponsiblePersonPresentUrk',
      'ProtocolPdf.TextStatementAgree',
      'ProtocolPdf.TextStatementCorrespontToFacts',
      'ProtocolPdf.TextStatementDisagree',
      'ProtocolPdf.TextStatementNoCorrespontToFacts',
      'ProtocolPdf.TextStatementNoRemarks',
      'ProtocolPdf.TextStatementRemarks',
      'ProtocolPdf.TextVeta',
      'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol1',
      'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol2',
      'ProtocolPdf.VacherinAndGruyereAuditorRequirementsHeaderCol3',
      'ProtocolPdf.VacherinAndGruyereNotApplicable',
      'ProtocolPdf.VacherinAndGruyereTextHeader',
      'ProtocolPdf.VacherinAndGruyereTVDHeaderCol1',
      'ProtocolPdf.VacherinAndGruyereTVDHeaderCol2',
      'ProtocolPdf.VacherinAndGruyereTVDHeaderCol3Part1',
      'ProtocolPdf.VacherinAndGruyereNotApplicable',
      'ProtocolPdf.VacherinAndGruyereUnitCanton',
      'ProtocolPdf.VacherinAndGruyereYes',
      'ProtocolPdf.VacherinAndGruyereNot',
      'ProtocolPdf.VacherinPageFooterRow2Part1',
      'ProtocolPdf.VacherinPageHeader',
      'ProtocolPdf.VacherinRequirementsArticle2Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle2Column3Row1',
      'ProtocolPdf.VacherinRequirementsArticle2Column3Row2',
      'ProtocolPdf.VacherinRequirementsArticle2Column3Row3',
      'ProtocolPdf.VacherinRequirementsArticle6Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle6Column3Row1',
      'ProtocolPdf.VacherinRequirementsArticle7Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle7Column3Row1',
      'ProtocolPdf.VacherinRequirementsArticle8Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle8Column3Row1',
      'ProtocolPdf.VacherinRequirementsArticle8Column3Row2',
      'ProtocolPdf.VacherinRequirementsArticle8Column3Row3',
      'ProtocolPdf.VacherinRequirementsArticle8Column3Row4',
      'ProtocolPdf.VacherinRequirementsArticle8And9',
      'ProtocolPdf.VacherinRequirementsArticle8And9Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle8And9Column2Row1',
      'ProtocolPdf.VacherinRequirementsArticle8And9Column3Row1Part1',
      'ProtocolPdf.VacherinRequirementsArticle8And9Column3Row1Part2',
      'ProtocolPdf.VacherinRequirementsArticle10Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle10Column1Row2',
      'ProtocolPdf.VacherinRequirementsArticle10Column1Row3',
      'ProtocolPdf.VacherinRequirementsArticle11Column1Row1',
      'ProtocolPdf.VacherinRequirementsArticle11Column3Row1',
      'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part1',
      'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part2',
      'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part3',
      'ProtocolPdf.VacherinRequirementsArticle11Column3Row3Part4',
      'ProtocolPdf.VacherinRequirementsLegendRow1',
      'ProtocolPdf.VacherinRequirementsLegendRow2Part2',
      'ProtocolPdf.VacherinTvdRow2Col1Part1',
      'ProtocolPdf.VacherinTvdRow2Col1Part2',
      'ProtocolPdf.VacherinTvdRow2Col1Part3',
      'ProtocolPdf.VacherinTvdRow2Col1Part4',
    ]);
  }

  private static calculateDefectsTableColumnWidthDeadline(): number {
    return (
      this.maxXValue -
      this.marginX -
      this.defectsTableColumnWidthNumber -
      this.defectsTableColumnWidthDefect -
      this.defectsTableColumnWidthMeasure
    );
  }

  private static calculateDefectsCorrectionTableColumnWidthCorrected(): number {
    return (
      this.maxXValue -
      this.marginX -
      this.defectsCorrectionTableColumnWidthNumber -
      this.defectsCorrectionTableColumnWidthDefect -
      this.defectsCorrectionTableColumnWidthMeasure -
      this.defectsCorrectionTableColumnWidthDeadline
    );
  }

  private static calculateSignatureBlockWidthDottedLine(): number {
    return (this.maxXValue - this.marginX - 2 * this.signatureBlockWidthLabel) / 2.0;
  }
}
